import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import classNames from "classnames"

import SEO from "./SEO"
import Footer from "./Footer"
import Navbar from "./Navbar"
import Container from "./Container"
import Notification from "./Notification"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import useHomeImages from "../StaticPages/hooks/useHomeImages"
import ProgressBar from "../Elements/ProgressBar"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {number} currentStep current step on the progress tracker
 * @param {number} totalSteps total steps of the progress tracker
 * @param {array} path array
 * @param {object} display settings to display footer/helper banner
 * @param {} children
 */

const Layout = ({
  children,
  title,
  subtitle,
  currentStep,
  totalSteps,
  seoTitle,
  display = {
    footer: false,
    helpCenterBanner: false,
    bottomMargin: true,
    radialStepper: true,
    progressBar: true,
  },
  path,
  pageContext,
  isWellness = false
}) => {
  let pathArray = path ? path.split("/") : []
  let data = useHomeImages()
  let orangePolygon = data?.orangePolygon.childImageSharp.fixed
  let tealPolygon = data?.tealPolygon.childImageSharp.fixed

  return (
    <Fragment>
      <SEO title={seoTitle} />
      { isWellness === false && <Notification pageContext={pageContext} /> }
      <Navbar isWellness={isWellness} path={pathArray} />
      {!!currentStep && !!totalSteps && display?.progressBar === true ? (
        <div>
          <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        </div>
      ) : null}
      <Img
        fixed={orangePolygon}
        alt="Orange Polygon"
        style={{ position: "absolute", right: 0, top: 10, zIndex: -1 }}
        className="is-hidden-mobile"
      />
      {!isWellness && (<Img
        fixed={tealPolygon}
        alt="Teal Polygon"
        style={{ position: "absolute", left: -20, top: 750, zIndex: -1 }}
        className="is-hidden-mobile"
      />)}
      {title && (
        <Container isCentered>
          <div className="is-align-items-center">
            <h2
              className={classNames(
                "has-text-centered mb-0 has-text-primary mx-2-mobile is-size-3-mobile",
                {
                  "pb-0": !subtitle,
                  "pb-2": !!subtitle,
                }
              )}
            >
              {title}
              {subtitle && (
                <h4 className="subtitle has-text-grey has-text-centered my-1 is-size-4 mx-1-mobile is-size-5-mobile">
                  {subtitle}
                </h4>
              )}
            </h2>
          </div>
        </Container>
      )}
      <main className={classNames({ "mb-3": display?.bottomMargin })}>
        {children}
      </main>
      {display.helpCenterBanner && (
        <HelpCenterBanner module={pageContext?.module?.name} />
      )}
      {display.footer && <Footer />}
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  path: PropTypes.array,
}

export default Layout
