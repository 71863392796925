import { useStaticQuery, graphql } from "gatsby"

const useHomeImages = () => {
  const data = useStaticQuery(graphql`
    {
      teleconsult: file(relativePath: { eq: "icons/teleconsult.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      refills: file(relativePath: { eq: "icons/syrup.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      medicines: file(relativePath: { eq: "icons/medicines.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      delivery: file(relativePath: { eq: "icons/order.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      reimburse: file(relativePath: { eq: "icons/reimburse.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      branded: file(relativePath: { eq: "icons/branded medicines.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescription: file(relativePath: { eq: "icons/prescription.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      epharmacy: file(relativePath: { eq: "icons/epharmacy.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo: file(relativePath: { eq: "logos/foundever.png" }) {
        childImageSharp {
          fixed(width: 230) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      orangePolygon: file(relativePath: { eq: "orange polygon.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tealPolygon: file(relativePath: { eq: "teal polygon.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  // const teleconsult = data?.teleconsult?.childImageSharp?.fixed?.src
  // const refills = data?.refills?.childImageSharp?.fixed?.src
  // const medicines = data?.medicines?.childImageSharp?.fixed?.src
  // const delivery = data?.delivery?.childImageSharp?.fixed?.src

  return data
}

export default useHomeImages
