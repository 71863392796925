import classNames from "classnames"
import React, { useEffect, useRef } from "react"

import styles from "../../components/Layout/utils/layout.module.scss"

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progressRef = useRef()
  let stepValue = currentStep - 1

  const handleProgressAnimation = () => {
    progressRef.current.value = stepValue += 1
  }

  useEffect(() => {
    handleProgressAnimation()
  })

  return (
    <progress
      className={classNames("progress is-warning", styles["progressBar"])}
      ref={progressRef}
      value={stepValue}
      max={totalSteps}
    />
  )
}

export default ProgressBar
