import React, { Fragment, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "./utils/layout.module.scss"

const Navbar = ( {isWellness} ) => {
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCircle: file(relativePath: { eq: "logos/medgrocer-square.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logoLong = data.logoLong.childImageSharp.fixed
  const logoCircle = data.logoCircle.childImageSharp.fixed

  const [isMenuActive, setMenuActive] = useState(false)

  const Logo = () => {
    return (
      <Link to="/">
        <div className={classNames(styles["navbar__brandLogos"])}>
          <Img fixed={logoLong} className="is-hidden-mobile" />
          <Img fixed={logoCircle} className="is-hidden-tablet" />
        </div>
      </Link>
    )
  }

  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-white">
        <div className={classNames("pl-1", styles["navbar__brand"])}>
          <Logo />
        </div>
        {isWellness === false && 
        <div
          className={classNames("navbar-menu", styles["navbar__menu"], {
            "is-active": isMenuActive,
          })}
        >
          <div
            className={classNames(
              "navbar-end mr-1",
              styles["navbar__alignCenter"]
            )}
          >
            <a
              href="https://maxi.care/MGtelecon"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-item has-text-weight-semibold has-text-secondary"
            >
              Teleconsult
            </a>
            <Link
              className="navbar-item has-text-weight-semibold has-text-secondary"
              to="/medensure"
            >
              MedEnsure
            </Link>
            <Link
              className="navbar-item has-text-weight-semibold has-text-secondary"
              to="/flexmed"
            >
              FlexMed
            </Link>
          </div>
        </div>
        }
      </nav>
      <div
        role="button"
        tabIndex={0}
        aria-label="Navbar Background"
        onKeyDown={(event) => {
          if (event.key === "Enter") setMenuActive(false)
        }}
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
