import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Container from "layout/Container"
import sitemap from "./utils/sitemap.json"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logoLong = data.logoLong.childImageSharp.fixed

  return (
    <footer className="footer px-1">
      <Container isCentered mobile={12} tablet={10} desktop={10} fullhd={8}>
        <div className="columns is-centered">
          <div className="column is-5 pl-0 pr-2">
            <Link to="/">
              <Img fixed={logoLong} />
            </Link>
            <p className="helper">
              MedGrocer's digitally-enabled corporate healthcare solutions and
              patient programs empower our customers to get their medicines,
              vaccines, and teleconsultations conveniently, cost-effectively,
              and intelligently.
            </p>
            <p className="helper pt-1">
              Every year, we serve over one million Filipinos either directly or
              through partnerships with employers and pharmaceutical companies.
            </p>

            <p className="helper pt-1">
              © {currentYear}, MedGrocer · MedGrocer (MG Health Solutions, Inc.)
            </p>
          </div>

          {sitemap.map((section, index) => (
            <div
              className={classNames(section.classnames, "column pl-0")}
              key={index}
            >
              <h4 className="mt-0 has-text-primary">{section.section}</h4>
              {section?.subsections?.length > 0 ? (
                <div>
                  {section.subsections.map((subsection) => (
                    <div>
                      <p className="mt-1 helper has-text-grey has-text-weight-bold is-uppercase">
                        {subsection.name}
                      </p>
                      {subsection.links.map((link) => (
                        <Link
                          to={`/${link.url}`}
                          className="helper"
                          key={link.url}
                        >
                          {link.label}
                          <br />
                        </Link>
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <p>
                  {section.links.map((link) => (
                    <Link to={`/${link.url}`} className="helper" key={link.url}>
                      {link.label}
                      <br />
                    </Link>
                  ))}
                </p>
              )}
            </div>
          ))}
        </div>
      </Container>
    </footer>
  )
}

export default Footer
